import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/pages/authentication/login/login.component';
import { RegisterComponent } from './components/pages/authentication/register/register.component';
import { ForgotPasswordComponent } from './components/pages/authentication/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/pages/authentication/reset-password/reset-password.component';
import { CustomersComponent } from './master/customers/customers.component';
import { CustomerDetailsComponent } from './master/customer-details/customer-details.component';
import { SearchCustomerComponent } from './search-customer/search-customer.component';
import { AuthenticationComponent } from './components/pages/authentication/authentication/authentication.component';
import { NotificatonComponent } from './notificaton/notificaton.component';
import { CutomerSearchComponent } from './cutomer-search/cutomer-search.component';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import { LoadPageComponent } from './load-page/load-page.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [ 
  { path: 'cutomer-search', component: CutomerSearchComponent , canActivate: [AuthGuard]},
  { path: 'add-customer', component: CustomersComponent, canActivate: [AuthGuard] },
  { path: 'customer-details', component: CustomerDetailsComponent , canActivate: [AuthGuard]},
  { path: 'search_customer', component: SearchCustomerComponent, canActivate: [AuthGuard] },
  { path: 'notification', component: NotificatonComponent, canActivate: [AuthGuard] },
  { path: 'sales-dashboard', component: SalesDashboardComponent , canActivate: [AuthGuard]},
  { path: 'loading', component: LoadPageComponent , canActivate: [AuthGuard]},  
  { path: '', component: LoginComponent },
  {
    path: 'authentication', component: AuthenticationComponent,
    children: [
      { path: 'login', pathMatch: 'full', data: { breadcrumb: 'Login' }, component: LoginComponent },
      { path: 'register', data: { breadcrumb: 'Register' }, component: RegisterComponent },
      { path: 'forgot-password', data: { breadcrumb: 'Forgot Password' }, component: ForgotPasswordComponent },
      { path: 'reset-password', data: { breadcrumb: 'Reset Password' }, component: ResetPasswordComponent },
    ]
  },

  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) , canActivate: [AuthGuard]},
  { path: 'master', loadChildren: () => import('./master/master.module').then(m => m.MasterModule), canActivate: [AuthGuard] },
  
  { path: 'transaction', loadChildren: () => import('./transaction/transaction.module').then(m => m.TransactionModule) , canActivate: [AuthGuard]},
  { path: 'report', loadChildren: () => import('./report/report.module').then(m => m.ReportModule), canActivate: [AuthGuard] },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
