import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public http: HttpClient,public router:Router,public appservice:AppService) {
 
     }
     addReset() {
      this.appservice.isadd = "0";
      return this.router.navigate(['settings/change-password']);
    }
    
     lenth:number=0;

    
     logout() {
      this.http.get(`${this.appservice.Server_URL}Api/Common/Logout`, { withCredentials: true })
        .subscribe(
          (res: any) => {
          if (JSON.parse(res).success) {
              // Clear local data
              localStorage.clear();
              sessionStorage.clear();
              
              this.appservice.Menu_Rows=[];
  
              // Redirect to login page
              
            this.appservice.Company="";
            this.router.navigate(['/'], { replaceUrl: true });
          } else {
              console.error('Logout failed:', res.message);
          }
      });
  }

     get_Home()
     {
      return this.router.navigate(['/transaction/reception-dashboard']);

     }

     get_Search()
     {
      return this.router.navigate(['/transaction/search-patient']);

     }
     get_Help()
     {
      return this.router.navigate(['/settings/help']);

     }
     Bill()
     {
      return this.router.navigate(['/transaction/patient-entry']);
     }

     
     Result()
     {
      return this.router.navigate(['/transaction/test-entry-details']);
     }
     
     search()
     {
      return this.router.navigate(['/transaction/search-patient']);

     }


    ngOnInit() {
    }

   
}
