<!-- Start Login Area -->
<div class="login-container">
    <!-- Left Section with Image -->
    <div class="login-image mhide">
        <img src="assets/img/Lab.png" alt="Quabha Finnance Mananagement System">
    </div>

    <!-- Right Section with Login Form -->
    <div class="login-form">
        <div class="form-wrapper">
            <!-- Logo -->
            <div class="text-center">
                <img src="assets/img/Chef.png" alt="Company Logo" class="login-logo">
            </div>

            <!-- Title -->
            <h2 class="login-title text-center">Laboratory Management</h2>
            <p class="login-subtitle text-center">Login</p>

            <!-- Login Form -->
            <form (ngSubmit)="login()">
                <!-- Username Field -->
                <div class="form-group">
                    <label for="username">User Name</label>
                    <input type="text" 
                           id="username" 
                           name="Username" 
                           class="form-control" 
                           [(ngModel)]="addD.Username" 
                           required 
                           placeholder="Enter your username" 
                           #usernameInput
                           autofocus>
                </div>

                <!-- Password Field -->
                <div class="form-group password-group">
                    <label for="password">Password</label>
                    <div class="input-wrapper">
                        <input [type]="showPassword ? 'text' : 'password'" 
                               id="password" 
                               name="Password" 
                               class="form-control" 
                               [(ngModel)]="addD.Password" 
                               (keydown.enter)="onEnterPassword()"  
                               required 
                               placeholder="Enter your password">
                        <i class="bx toggle-icon" 
                           [ngClass]="showPassword ? 'bx-hide' : 'bx-show'" 
                           (click)="togglePasswordVisibility()"></i>
                    </div>
                </div>

                <!-- Remember Me Checkbox -->
                <div class="form-group">
                    <label>
                        <input type="checkbox" [(ngModel)]="rememberMe" name="rememberMe">
                        Remember Me
                    </label>
                </div>

                <!-- Error Message -->
                <div class="form-group">
                    <span class="error-message">{{errorMessage}}</span>
                </div>

                <!-- Login Button -->
                <div class="form-group">
                    <button type="submit" [disabled]="isButtonDisabled" class="btn btn-primary">
                        <span *ngIf="isButtonDisabled" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Login
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Login Area -->
