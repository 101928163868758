import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'src/app/app.service';
import * as CryptoJS from 'crypto-js'; // Import CryptoJS

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('usernameInput') usernameInput!: ElementRef;

  public addD = { Username: '', Password: '' };
  public errorMessage = '';
  public isButtonDisabled = false;
  public isLoading = false;
  public rememberMe: boolean = false;
  public showPassword: boolean = false;
  

  private encryptionKey = 'O9x2FpQzMt2Iw9aLZ3eN6mVHY5sT8bUn'; // Use a secure key

  constructor(
    private http: HttpClient,
    private appService: AppService,
    private router: Router
  ) {}

  /**
   * Encrypt data using AES encryption.
   * @param data The data to encrypt
   * @returns Encrypted data as a string
   */
  private encryptData(data: any): string {
    const key = CryptoJS.enc.Utf8.parse(this.encryptionKey.padEnd(32, '0').substring(0, 32)); // Ensure 32-byte key
    const iv = CryptoJS.enc.Utf8.parse('0000000000000000'); // 16-byte IV

    return CryptoJS.AES.encrypt(JSON.stringify(data), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(); // Return encrypted data as Base64
  }

  /**
   * Login function triggered on form submission.
   */
  public login(): void {
    if (!this.addD.Username || !this.addD.Password) {
      this.errorMessage = 'Username and Password are required.';
      return;
    }

    this.isButtonDisabled = true;
    this.errorMessage = '';


    // Encrypt the payload before sending
    const encryptedPayload = this.encryptData({
      UserName: this.addD.Username,
      Password: this.addD.Password
    });

    this.http.post(`${this.appService.Server_URL}Api/Common/GetLogin`, { encryptedData: encryptedPayload }, { withCredentials: true })
      .subscribe({
        next: (res: any) => {
          const response = JSON.parse(res);
          if (response.success) {

            this.handleSuccessfulLogin(response.userData);

            if (this.rememberMe) {
              localStorage.setItem('username', this.addD.Username);

            } else {
              localStorage.removeItem('username');
            }
          } else {
            this.handleFailedLogin(response.message || 'Invalid Username and Password.');
          }
        },
        error: (error: any) => {
          this.handleFailedLogin('An error occurred while logging in. Please try again.');
          console.error('Login error:', error);
        }
      });
  }


  onEnterPassword()
  {
    if (this.addD.Password) {
      this.login();
    } else {
      this.errorMessage = 'Password is required.';
    }
  }

  /**
   * Handle a successful login.
   * @param userData User details from the API response
   */
  private handleSuccessfulLogin(userData: any): void {
    this.appService.Current_User = userData;
    this.appService.Company = `_${userData.UM_Company}`;
    this.isButtonDisabled = false;

    this.router.navigate([userData.Route_URL]);
    this.appService.get_Field_Setting();

   // this.appService.loadMenu(userData.UM_Rights).then(() => {
    //this.router.navigate([this.appService.User_Load_URL]);
    //});
  }

  /**
   * Handle a failed login attempt.
   * @param message Error message to display
   */
  private handleFailedLogin(message: string): void {
    this.isButtonDisabled = false;
    this.errorMessage = message;
  }

  /**
   * Toggle password visibility.
   */
  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  /**
   * Focus on the username input field.
   */
  private focusOnUsername(): void {
    setTimeout(() => {
      this.usernameInput.nativeElement.focus();
    }, 0);
  }

  ngOnInit(): void {
    const savedUsername = localStorage.getItem('username');
    if (savedUsername) {
      this.addD.Username = savedUsername;
      this.rememberMe = true;
    }
    this.focusOnUsername(); // Ensure focus on username input
  }
}
