import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppService } from './app.service'; // Adjust the path to your AppService
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private appService: AppService, private router: Router,private toastr: ToastrService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    try {
      const isAuthenticated = await this.appService.validateToken();

      if (isAuthenticated) {
        // Allow access if the user is authenticated
       
        
          const ismenu = await this.appService.loadMenu(this.appService.Rights_ID);
          // Additional logic if needed
        
        return true;
      } else {
        
        // Store the attempted URL for post-login redirection
        this.toastr.error("Invalid Sesstion ", "Error", { timeOut: 3000 });
        sessionStorage.setItem('lastVisitedPage', state.url);
        this.router.navigate(['/']);
        return false;
      }
    } catch (error) {
      console.error('Error in AuthGuard:', error);
      this.router.navigate(['/']);
      return false;
    }
  }
}
