
<ng-container >

  <div class="spin_block" *ngIf="appservice.ismenuloading">
  
    <div class="d-flex justify-content-center">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

  </div>

  <app-sidebar *ngIf="showSidebarAndHeader"></app-sidebar>
  <!-- Start Main Content Wrapper Area -->
  <div  class="main-content d-flex flex-column">


    <app-header *ngIf="showSidebarAndHeader" ></app-header>
      <router-outlet  ></router-outlet>
     
      <div class="flex-grow-1"></div>
    <!-- <app-footer></app-footer> -->
  </div>
  

  

  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

  
</ng-container>


